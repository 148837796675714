// 
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$form-range-track-bg: none;
$form-range-thumb-width: 2rem;
$form-range-thumb-height: 2rem;