// 
// user.scss
// Use this to write your custom SCSS
//

/* Button header */
.switch-wrapper input {
    position: absolute;
    left: -9999px;
}

.switch-wrapper input+label {
    width: 110px;
    height: 50px;
    background: #189cd9;
    display: inline-block;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    z-index: 3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.04);
}

.switch-wrapper .pdlc input+label::after {
    position: absolute;
    content: "Folie uit";
    text-transform: uppercase;
    width: 100%;
    top: 100%;
    text-align: center;
    left: 0;
    padding-top: 10px;
    -webkit-transition: all 0.2s;
    -mox-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.switch-wrapper .beamer input+label::after {
    position: absolute;
    content: "beamer uit";
    text-transform: uppercase;
    width: 100%;
    top: 100%;
    text-align: center;
    left: 0;
    padding-top: 10px;
    -webkit-transition: all 0.2s;
    -mox-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.switch-wrapper input+label span {
    position: absolute;
    height: 42px;
    top: 4px;
    left: 4px;
    border-radius: 42px;
    width: 42px;
    background: white;
    -webkit-transition: all 0.3s;
    -mox-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.switch-wrapper input+label span em {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transition: all 0.3s;
    -mox-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.switch-wrapper input+label span em::after {
    height: 100%;
    width: 2px;
    background: black;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 0;
}

.switch-wrapper input+label span em::before {
    left: 0;
    width: 100%;
    height: 2px;
    background: black;
    top: 50%;
    margin-top: -1px;
    content: "";
    position: absolute;
    -webkit-transition: all 0.3s;
    -mox-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.switch-wrapper .pdlc input:checked+label::after {
    content: "Folie aan";
}

.switch-wrapper .beamer input:checked+label::after {
    content: "beamer aan";
}

.switch-wrapper input:checked+label span {
    background: #0181a4;
    left: calc(100% - 46px);
}

.switch-wrapper input:checked+label span em {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -6px;
}

.switch-wrapper input:checked+label span em::before {
    left: 0;
    width: 50%;
    height: 2px;
    background: black;
    top: 100%;
    margin-top: -2px;
    content: "";
    position: absolute;
}


